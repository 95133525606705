<!-- ----------------------------------------------------------------------- -->
<!--
name    : SCHEDULE VIEW

type    : view

route   : /scheduling
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="schedule-view">
    <!-- router-view will be replaced with whichever route is active -->
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'ScheduleView',
    components: {},
  };
</script>
